module.exports = {
  DEBUG: process.env.NODE_ENV === 'development',

  siteName: 'Softubmidlands',
  author: 'accentuate <hello@accentuate.agency>',
  description:
    'USA Made Hot Tubs From Leading USA Manufacturer, Artesian Spas and Softub. With over 20 years providing outstanding customer service',
  siteUrl: 'https://www.spasandhottubs.co.uk',
  logo: '/images/logo.png',
  metaLogo: '/images/favicon.ico',
  projectKey: 'spasandhottubs',
  currency: '£',
  telephone: '07793 988123',
  email: 'info@spasandhotubs.co.uk',
  location: 'Palmers @ Ullesthorpe Garden Centre',
  youtube: 'https://youtube.com',
  twitter: 'https://www.twitter.com',
  instagram: 'https://www.instagram.com',

  type: 'website',
  googleAnalytics: 'UA-1231313',
  backgroundColor: '#e0e0e0',
  themeColor: '#239F9D',
  sanityId: 'q4m5bz69', // midland
  dataset: 'production',
  apiUrl: 'https://midland-api-git-api-accentuate.vercel.app/api/graphql',
  //   apiUrl: 'http://localhost:3000/api/graphql',
  mailchimp:
    'https://spasandhottubs.us19.list-manage.com/subscribe/post?u=8102870fc94236169996f32e0&amp;id=76b8958986&amp;f_id=00cb43e4f0',
};
