import React from 'react';
import { StoreProvider } from 'easy-peasy';
import { PersistGate } from 'redux-persist/integration/react';
import LoadingOverlay from 'react-loading-overlay';

import { ThemeProvider } from './themeContext';
import { store, persistor } from '../store';

export const wrapClient = ({ element }) => (
  <PersistGate
    loading={<LoadingOverlay active spinner />}
    persistor={persistor}
  >
    <StoreProvider store={store}>
      <ThemeProvider>{element}</ThemeProvider>
    </StoreProvider>
  </PersistGate>
);

export const wrapSSR = ({ element }) => (
  <StoreProvider store={store}>
    <ThemeProvider>{element}</ThemeProvider>
  </StoreProvider>
);

export default { wrapClient, wrapSSR };
